<template>
  <div class="enable-range" @click.native.capture="hideBtn">
    <div class="enable-range-list">
      <!-- 选择简档 -->
      <div class="title">{{ $t("label.partner.workspace.member.choice") }}</div>
      <!-- 筛选  component.timeline.button.filter-->
      <!-- 请选择  label.tabpage.pleaseSelectz -->
      <div class="screen">
        {{ $t("component.timeline.button.filter") }}：<el-select
          v-model="value"
          :placeholder="$t('label.tabpage.pleaseSelectz')"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="select-Profile">
        <!-- 可选简档  已选简档 -->
        <shuttle-box
          ref="shuttelBox"
          :title="[
            $t('label_partner_workspace_member_profile'),
            $t('label_partner_workspace_member_selectedprofile'),
          ]"
          :viewUnselectedFieldList="profileList"
          :viewSelectedFieldArr="selectedprofileList"
          @selFun="selFunPro"
        ></shuttle-box>
      </div>
      <!-- 选择权限集 -->
      <div class="title">
        {{ $t("label.partner.workspace.member.choicepermission") }}
      </div>
      <div class="select-Profile">
        <!-- 可选权限集  已选权限集  -->
        <shuttle-box
          ref="shuttelBoxSecond"
          :title="[
            $t('label_partner_workspace_member_permission'),
            $t('label.partner.workspace.member.selectedpermission'),
          ]"
          :viewUnselectedFieldList="permissionList"
          :viewSelectedFieldArr="selectedpermissionList"
          @selFun="selFunPer"
        ></shuttle-box>
      </div>
    </div>
    <div class="bottom">
      <!-- 取消  保存 -->
      <el-button @click="cancelNewBuid" style="color: #666666">{{
        $t("label.emailsync.button.cancel")
      }}</el-button>
      <el-button type="primary" @click="save" style="color: white">{{
        $t("component.telerecord.button.save")
      }}</el-button>
    </div>
  </div>
</template>

<script>
import shuttleBox from "@/views/systemSettings/components/partnerCloudSetting/components/shuttleBox.vue";
import {
  queryPermissionList,
  queryProfileListJson,
  savePartnerMember,
} from "./api"; //获取域名

export default {
  props: {
    partnerid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      //简档id
      proId: "",
      //权限集id
      perId: "",
      //未选择简档
      profileList: [],
      //已选择简档
      selectedprofileList: [],
      //未选择权限集
      permissionList: [],
      //已选择权限集
      selectedpermissionList: [],
      options: [
        {
          value: "partner",
          label: "CloudCC Partner 简档",
        },
        {
          value: "cloudcc",
          label: "CloudCC 简档",
        },
        {
          value: "all",
          label: "全部",
        },
      ],
      value: "partner",
    };
  },
  watch: {
    partnerid() {
      this.queryPermissionList();
    },
    value() {
      this.queryProfileListJson();
    },
  },
  mounted() {
    this.queryPermissionList();
    this.queryProfileListJson();
  },
  methods: {
    //取消可选成员和可选字段组件的icon
    hideBtn() {
      if (this.$refs.shuttelBox !== undefined) {
        this.$refs.shuttelBox.hideBtn();
      }
      if (this.$refs.shuttelBoxSecond !== undefined) {
        this.$refs.shuttelBoxSecond.hideBtn();
      }
    },
    //搜索切换简档获取的数据
    async queryProfileListJson() {
      let result = await queryProfileListJson({ profiletype: this.value });
      let array = JSON.parse(result.data.profileListJson);
      array.forEach((res) => (res.labelName = res.label));
      this.profileList = array;
    },
    //简档id
    selFunPro(val) {
      this.proId = val;
    },
    //权限集id
    selFunPer(val) {
      this.perId = val;
    },
    //获取伙伴云简档及权限集
    async queryPermissionList() {
      let result = await queryPermissionList({ partnerid: this.partnerid });
      if (result.result) {
        result.data.selectedprofileList.forEach((res) => {
          res.labelName = res.label;
          res.id = res.permissionid;
        });
        this.selectedprofileList = result.data.selectedprofileList;
        result.data.selectedpermissionList.forEach((res) => {
          res.labelName = res.name;
          res.id = res.permissionid ? res.permissionid : res.id;
        });
        this.selectedpermissionList = result.data.selectedpermissionList;
        result.data.permissionList.forEach((res) => (res.labelName = res.name));
        this.permissionList = result.data.permissionList;
      }
    },
    //取消新建
    cancelNewBuid() {
      this.$Bus.$emit("close-other-edit");
      this.$emit("closeNewBuild");
    },
    //保存新建
    async save() {
      // 简档与权限集仅判断一个存在记录即可保存成功,否则提示至少选择一个菜单
      if (this.proId === "" && this.perId === "") {
        this.$message.warning(this.$i18n.t("label.partnerCloud.selectOne"));
        return;
      }
      let option = {
        partnerid: this.partnerid, //伙伴云id
        permissionids: this.perId, //已选择权限集id
        profileids: this.proId, //已选择简档id
      };
      let res = await savePartnerMember(option);
      // 保存成功
      if (res.result) {
        this.$message.success(this.$i18n.t("savesuccess"));
      }
    },
  },
  components: {
    shuttleBox,
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  width: 200px;
  height: 30px;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
::v-deep .el-button {
  color: #006dcc;
  font-size: 12px;
  padding: 8px 19px;
}
.enable-range {
  padding: 30px 10px;
  height: 100%;
  overflow: auto;
  .enable-range-list {
    width: 100%;
  }
  .title {
    color: #888888;
    margin-bottom: 30px;
    font-weight: bold;
  }
  ::v-deep .el-select {
    width: 200px;
  }
  .select-Profile {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .bottom {
    position: absolute;
    background: white;
    width: 100%;
    height: 60px;
    border-top: 1px solid #dddbda;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
</style>