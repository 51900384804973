import axios from '@/config/httpConfig'

// 图片字段上传
export function uploadImg(data) {
    return axios.post('file/uploadImg', data)
}
// 根据id查询伙伴云登陆页面配置
export function queryPartnerLoginSetupById(data) {
    return axios.post('/partnerSetup/queryPartnerLoginSetupById', data)
}
// 查询伙伴云一级域名
export function getPartnerFirstUrl(data) {
    return axios.get('/partnerSetup/getPartnerFirstUrl', data)
}
// 查询伙伴云设置
export function queryPartnerSetup(data) {
    return axios.get('/partnerSetup/queryPartnerSetup', data)
}
// 查询伙伴云列表
export function queryPartnerList(data) {
    return axios.get('/partnerSetup/queryPartnerList', data)
}
// 检查伙伴云数量
export function checkPartnerNum(data) {
    return axios.post('/partnerSetup/checkPartnerNum', data)
}

// 根据id查询伙伴云
export function queryPartnerById(data) {
    return axios.post('/partnerSetup/queryPartnerById', data)
}

// 检查二级域名可用性
export function checkPartnerSecond(data) {
    return axios.post('/partnerSetup/checkPartnerSecond', data)
}
// 保存菜单
export function savePartnerTab(data) {
    return axios.post('/partnerSetup/savePartnerTab', data)
}
// 用户菜单查询
export function register(data) {
    return axios.post('/partnerSetup/queryPartnerTab', data)
}
// 清除照片
export function removeImg(data) {
    return axios.post('file/removeImg', data)
}
// 保存伙伴云
export function savePartner(data) {
    return axios.post('/partnerSetup/savePartner', data)
}
// 获取视图详细信息
export function GetViewInfo(data) {
    return axios.post('/view/getViewInfo', data)
}
// 获获取选项列表值
export function GetViewGetSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 获取币种信息
export function GetCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
}
// 获取伙伴云简档及权限集
export function queryPermissionList(data) {
    return axios.post('/partnerSetup/queryPermissionList', data)
}
// 搜索未选择简档
export function queryProfileListJson(data) {
    return axios.post('/partnerSetup/queryProfileListJson', data)
}
// 保存成员简档信息
export function savePartnerMember(data) {
    return axios.post('/partnerSetup/savePartnerMember', data)
}
// 获取伙伴logo
export function getLogoid(data) {
    return axios.post('/partnerSetup/getLogoid', data)
}

// 获取查找信息
export function getLookupInfo(data) {
    return axios.post('/lookup/getLookupInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}
