<template>
  <div class="selectProfile">
    <div class="shuttleBox">
      <!-- 背景图片 -->
      <div>
        <!-- title -->
        <div style="display: flex; align-items: center">
          <!-- 背景图片  (文件最大为2M)  上传图片  清除图片 -->
          <span class="firTitle">{{
            $t("label.partnerCloud.backgroundImage")
          }}</span>
          <el-upload
            class="upload-demo"
            action=""
            :http-request="httpRequest"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
          >
            <el-button>{{ $t("label.weixin.upload.pictures") }}</el-button>
          </el-upload>
          <el-button style="margin-left: 10px" @click="delePic('background')">{{
            $t("label.campaign.channel.clear.img")
          }}</el-button>
        </div>
        <!-- front-v1-partner-background-tooltip -->
        <div class="img-des">{{ $t("c176") }}</div>
        <!-- 图片预览 -->
        <div v-if="backImgSrc" class="maskFather">
          <div>
            <!-- 图片预览 -->
            <img :src="backImgSrc" class="avatar" />
          </div>
        </div>
        <div v-else class="maskFather">
          <el-upload
            action=""
            :show-file-list="false"
            list-type="picture-card"
            :http-request="httpRequest"
            :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-plus uploadAdd"></i>
          </el-upload>
        </div>
      </div>
      <!-- 页眉徽标 -->
      <div>
        <!-- title -->
        <div style="display: flex; align-items: center">
          <!-- 页眉徽标  文件最大为2M-->
          <span class="firTitle">{{
            $t("label_partner_workspace_login_logo")
          }}</span>
          <el-upload
            class="upload-demo"
            action=""
            :show-file-list="false"
            :http-request="httpLogoRequest"
            :before-upload="beforeAvatarUploadTwo"
          >
            <!-- 上传图片 -->
            <el-button>{{ $t("label.weixin.upload.pictures") }}</el-button>
          </el-upload>
          <!-- 清除图片 -->
          <el-button style="margin-left: 10px" @click="delePic('logo')">{{
            $t("label.campaign.channel.clear.img")
          }}</el-button>
        </div>
        <div class="img-des">{{ $t("c176") }}{{ $t("c177") }}</div>
        <!-- 图片预览 -->
        <div v-if="logPic" class="maskFather">
          <div>
            <!-- 图片预览 -->
            <img :src="logPic" class="avatar" id="avatar" />
          </div>
        </div>
        <div v-else class="maskFather">
          <el-upload
            action=""
            :show-file-list="false"
            list-type="picture-card"
            :http-request="httpLogoRequest"
            :before-upload="beforeAvatarUploadTwo"
          >
            <i class="el-icon-plus uploadAdd"></i>
          </el-upload>
        </div>
      </div>
      <!-- 伙伴logo -->
      <div>
        <!-- title -->
        <div style="display: flex; align-items: center">
          <!-- 页眉徽标  文件最大为2M-->
          <span class="firTitle">{{ $t("c179") }}</span>
          <el-upload
            class="upload-demo"
            action=""
            :show-file-list="false"
            :http-request="httpLogoRequest2"
            :before-upload="beforeAvatarUploadThird"
          >
            <!-- 上传图片 -->
            <el-button>{{ $t("label.weixin.upload.pictures") }}</el-button>
          </el-upload>
          <!-- 清除图片 -->
          <el-button style="margin-left: 10px" @click="delePic('logo2')">{{
            $t("label.campaign.channel.clear.img")
          }}</el-button>
        </div>
        <!-- front-v1-partner-image-tooltip -->
        <div class="img-des">{{ $t("c176") }}</div>
        <!-- 图片预览 -->
        <div v-if="logPic2" class="maskFather">
          <div>
            <!-- 图片预览 -->
            <img :src="logPic2" class="avatar" />
          </div>
        </div>
        <div v-else class="maskFather">
          <el-upload
            action=""
            :show-file-list="false"
            list-type="picture-card"
            :http-request="httpLogoRequest2"
            :before-upload="beforeAvatarUploadThird"
          >
            <i class="el-icon-plus uploadAdd"></i>
          </el-upload>
        </div>
      </div>
      <!-- 登录页面语言 -->
      <div class="module positionFlex">
        <!-- title -->
        <!-- 登录按钮 -->
        <div class="firTitle" style="width: 80px">登录页语言</div>
        <!-- 文本框 -->
        <div>
          <el-select v-model="languageValue">
            <el-option
              v-for="item in languageOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 登录按钮 -->
      <div class="module positionFlex">
        <!-- title -->
        <!-- 登录按钮 -->
        <div class="firTitle" style="width: 80px">
          {{ $t("label.partnerCloud.loginButton") }}
        </div>
        <!-- 文本框 -->
        <div><el-input v-model="loginBtn"></el-input></div>
      </div>
      <!-- 页脚 -->
      <div class="positionFlex">
        <!-- title -->
        <!-- 页脚 -->
        <div class="firTitle" style="width: 80px">
          {{ $t("label.partnerCloud.footer") }}
        </div>
        <!-- 文本框 -->
        <div><el-input v-model="pageFoot"></el-input></div>
      </div>
    </div>
    <!-- 底部按钮组 -->
    <div class="btnGroup">
      <el-button
        style="margin-left: 10px; color: white"
        type="primary"
        size="mini"
        @click="saveLoginSetting"
      >
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
      <el-button @click="cancelNewBuid" style="color: #666666" size="mini">
        <!-- 取消 -->
        {{ $t("label.cancel") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import * as loginSettingApi from "./api.js"; //获取域名
export default {
  props: {
    partnerid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      token: this.$CCDK.CCToken.getToken(),
      loginBtn: "",
      pageFoot: "",
      backImgSrc: "",
      logPic: "",
      backgroundImgId: "", //背景图上传获取图片的id
      headerImgId: "", //页眉微标上传获取图片的id
      formData: null, //保存背景图片的信息
      logoFormData: null, //保存页眉徽标图片信息
      partenerLogo: null,
      initData: null,
      logPic2: "",
      logoId: "",
      languageValue: "",
      languageOptions: [
        { value: "ZH", label: "中文" },
        { value: "EN", label: "English" },
      ], //不需要替换多语言
    };
  },
  mounted() {
    // 根据id查询伙伴云登陆页面配置
    this.configPartnerById();
  },
  watch: {
    partnerid() {
      this.configPartnerById();
    },
  },
  methods: {
    cancelNewBuid() {
      this.$Bus.$emit("close-other-edit");
      this.$emit("closeNewBuild");
    },
    // 上传背景图片
    httpRequest(data) {
      this.backImgSrc = URL.createObjectURL(data.file);
    },
    // 上传页眉微标
    httpLogoRequest(data) {
      this.logPic = URL.createObjectURL(data.file);
    },
    // 上传伙伴logo
    httpLogoRequest2(data) {
      this.logPic2 = URL.createObjectURL(data.file);
    },
    // 背景图片
    beforeAvatarUpload(file) {
      this.formData = new FormData();
      this.formData.append("file", file);
      this.formData.append("fileName", file.name);
      const imgType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error(
          this.$i18n.t("vue_label_notice_uploadavatar_exceed")
        ); //上传头像图片大小不能超过 2MB!
      }
      if (!imgType) {
        this.$message.error(this.$i18n.t("c178"));
      }
      if (isLt2M && imgType) {
        // 参数准备好 上传服务器
        loginSettingApi.uploadImg(this.formData).then((res) => {
          this.backgroundImgId = res.data.fileid;
        });
      }
      return isLt2M && imgType;
    },
    // 页眉微标
    beforeAvatarUploadTwo(file) {
      this.logoFormData = new FormData();
      this.logoFormData.append("file", file);
      this.logoFormData.append("fileName", file.name);
      const imgType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error(
          this.$i18n.t("vue_label_notice_uploadavatar_exceed")
        ); //上传头像图片大小不能超过 2MB!
      }
      if (!imgType) {
        this.$message.error(this.$i18n.t("c178"));
      }
      if (isLt2M && imgType) {
        // 参数准备好 上传服务器
        loginSettingApi.uploadImg(this.logoFormData).then((res) => {
          this.headerImgId = res.data.fileid;
        });
      }
      return isLt2M && imgType;
    },
    // 伙伴logo
    beforeAvatarUploadThird(file) {
      this.partenerLogo = new FormData();
      this.partenerLogo.append("file", file);
      this.partenerLogo.append("fileName", file.name);
      const imgType = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error(
          this.$i18n.t("vue_label_notice_uploadavatar_exceed")
        ); //上传头像图片大小不能超过 2MB!
      }
      if (!imgType) {
        this.$message.error(this.$i18n.t("c178"));
      }
      if (isLt2M && imgType) {
        // 参数准备好 上传服务器
        loginSettingApi.uploadImg(this.partenerLogo).then((res) => {
          this.logoId = res.data.fileid;
        });
      }
      return isLt2M && imgType;
    },
    delePic(type) {
      if (type == "background") {
        this.backImgSrc = "";
        this.backgroundImgId = "";
      } else if (type === "logo") {
        this.logPic = "";
        this.headerImgId = "";
      } else {
        this.logPic2 = "";
        this.logoId = "";
      }
    },
    // 调取根据id查询伙伴云登陆页面配置接口
    configPartnerById() {
      let option = {
        id: this.partnerid,
      };
      loginSettingApi.queryPartnerLoginSetupById(option).then((res) => {
        this.initData = JSON.stringify(res.data);
        if (res.data.logoimgid) {
          this.logPic = `${this.$cookies.get(
            "domainName"
          )}/querysome.action?m=viewImg&fileid=${res.data.logoimgid}&binding=${
            this.token
          }`;
          this.headerImgId = res.data.logoimgid;
        } else {
          this.logPic = "";
          this.headerImgId = "";
        }
        if (res.data.backlogoimgid) {
          this.backImgSrc = `${this.$cookies.get(
            "domainName"
          )}/querysome.action?m=viewImg&fileid=${
            res.data.backlogoimgid
          }&binding=${this.token}`;
          this.backgroundImgId = res.data.backlogoimgid;
        } else {
          this.backImgSrc = "";
          this.backgroundImgId = "";
        }
        if (res.data.partnerlogoimgid) {
          this.logPic2 = `${this.$cookies.get(
            "domainName"
          )}/querysome.action?m=viewImg&fileid=${
            res.data.partnerlogoimgid
          }&binding=${this.token}`;
          this.logoId = res.data.partnerlogoimgid;
        } else {
          this.logPic2 = "";
          this.logoId = "";
        }
        this.pageFoot = res.data.footertext;
        this.loginBtn = res.data.buttontext;
        this.languageValue = res.data.language ? res.data.language : "ZH";
      });
    },
    // 调取保存伙伴云登陆页面配置接口
    saveConfigPartner() {
      let backwidth = "";
      let backheight = "";
      if (this.headerImgId) {
        backwidth = document.getElementById("avatar").naturalWidth;
        backheight = document.getElementById("avatar").naturalHeight;
      }
      let op = {
        id: this.partnerid,
        footertext: this.pageFoot,
        logoimgid: this.headerImgId,
        backlogoimgid: this.backgroundImgId,
        buttontext: this.loginBtn,
        logolength: backwidth,
        logowidth: backheight,
        partnerlogoimgid: this.logoId,
        language: this.languageValue,
      };
      loginSettingApi.savePartner(op).then((res) => {
        if (res.result) {
          this.$message.success(this.$i18n.t("savesuccess")); //保存成功
        }
      });
    },
    // 点击保存按钮
    saveLoginSetting() {
      let obj = JSON.parse(this.initData);
      // 先判断 是否更改  如果更改  则调用清除图片的接口
      // 获取初始时的图片信息
      // 如果现存和初始不一致  且初始必须有图片
      if (this.headerImgId !== obj.logoimgid && obj.logoimgid) {
        // 调用删除图片接口
        loginSettingApi.removeImg({ fileid: obj.logoimgid });
      }
      if (this.backgroundImgId !== obj.backlogoimgid && obj.backlogoimgid) {
        // 调用删除图片接口
        loginSettingApi.removeImg({ fileid: obj.backlogoimgid });
      }
      if (this.logoId !== obj.partnerlogoimgid && obj.partnerlogoimgid) {
        // 调用删除图片接口
        loginSettingApi.removeImg({
          fileid: obj.partnerlogoimgid,
        });
      }
      // 保存伙伴云登陆页面配置
      this.saveConfigPartner();
    },
  },
};
</script>

<style lang="scss" scoped>
.shuttleBox {
  height: calc(100% - 30px);
  padding-bottom: 60px;
  overflow: auto;
}
.img-des {
  margin: 10px 0;
  color: #666;
}
.selectProfile {
  padding: 30px 10px;
  height: 100%;
}
.module {
  margin-bottom: 20px;
}
.firTitle {
  font-size: 12px;
  color: #080707;
  font-weight: 600;
  margin-right: 10px;
}
.secTitle {
  font-size: 12px;
  color: #3a3a3a;
  margin-right: 10px;
}
::v-deep .el-button {
  color: #006dcc;
  font-size: 12px;
  padding: 8px 19px;
}
img {
  width: 246px;
}
::v-deep .el-input__inner {
  width: 278px;
  height: 30px;
}
.positionFlex {
  display: flex;
  align-items: center;
}
.btnGroup {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid #dddbda;
  align-items: center;
  padding-right: 20px;
}
.maskFather {
  margin: 10px 0px;
}
::v-deep .el-upload--picture-card {
  width: 246px;
  height: 147px;
}
::v-deep .el-upload-list {
  display: none;
}
::v-deep .el-select .el-input__inner {
  width: 202.5px;
  font-size: 14px;
}
</style>