<template>
  <div class="set-up-page">
    <div class="title">{{ editSiteDataProp.name }}</div>
    <div class="describe">{{ editSiteDataProp.description }}</div>
    <div class="domain-name">
      {{ editSiteDataProp.dir_2rd }}
      <!-- 编辑 -->
      <el-button class="el-button" @click="editContent">{{
        $t("label.emailobject.edit")
      }}</el-button>
    </div>
    <div class="status">
      <!-- 状态 -->
      <span class="titles">{{ $t("label.import.index.state") }}</span
      ><span
        >{{ editSiteDataProp.isusing }}
        <!-- 禁用   启用-->
        <el-button
          v-show="editSiteDataProp.status == 'true'"
          @click="changeStatus"
          >{{ $t("label.emailsync.status.disabel") }}</el-button
        ><el-button
          v-show="editSiteDataProp.status == 'false'"
          @click="changeStatus"
          >{{ $t("label.emailtocloudcc.button.enable") }}</el-button
        ></span
      >
    </div>
    <div class="status">
      <!-- 模板名称   菜单-->
      <span class="template">{{ $t("label.partner.template.name") }}</span
      ><span>{{ $t("label_partner_workspace_tab") }}</span>
    </div>
    <!-- 编辑 -->
    <el-dialog
      :visible.sync="isEditContent"
      :title="$t('label.emailobject.edit')"
      width="40%"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
        >
          <!-- 名称 -->
          <el-form-item :label="$t('label.emailtocloudcc.name')" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="url" prop="url">
            {{ editSiteDataProp.baseUrl }}
            <el-input v-model="ruleForm.url"></el-input>
            <span
              v-show="urlSuccess"
              :style="{ color: isSuccess ? '#06844B' : '#cc463d' }"
              >{{ info }}</span
            >
          </el-form-item>
          <!-- 描述 -->
          <el-form-item
            :label="$t('label.appointmentdetail.wizard.title13')"
            prop="desc"
          >
            <el-input type="textarea" v-model="ruleForm.desc"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="save('ruleForm')">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { queryPartnerById, savePartner, checkPartnerSecond } from "./api"; //获取域名

export default {
  props: {
    editSiteData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    editSiteDataProp() {
      return this.editSiteData;
    },
  },
  data() {
    return {
      backupUrl: this.editSiteData.dir2rd,
      urlSuccess: false,
      isSuccess: false,
      info: "",
      isEditContent: false,
      ruleForm: {
        name: this.editSiteData.name,
        url: this.editSiteData.dir2rd,
        desc: this.editSiteData.description,
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$i18n.t("vue_label_homepage_enter_name"),
            trigger: "blur",
          }, //请输入名称
        ],
        url: [
          {
            required: true,
            message: this.$i18n.t("label.partnerCloud.enterAddress"),
            trigger: "blur",
          }, //请输入地址
        ],
      },
    };
  },
  watch: {
    editSiteDataProp: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.ruleForm = {
            name: this.editSiteDataProp.name,
            url: this.editSiteDataProp.dir2rd,
            desc: this.editSiteDataProp.description,
          };
          this.backupUrl = this.editSiteDataProp.dir2rd;
        }
      },
      deep: true,
    },
  },
  methods: {
    //更改状态
    changeStatus() {
      this.saveStatus();
    },
    //更改禁用与启用

    async saveStatus() {
      let option = {
        id: this.editSiteDataProp.id,
        isusing: this.editSiteDataProp.status == "true" ? "false" : "true",
      };
      let res = await savePartner(option);
      if (res.result == true) {
        this.editSiteDataProp.status =
          this.editSiteDataProp.status == "true" ? "false" : "true"; //启用   禁用   启用
        this.editSiteDataProp.isusing =
          this.editSiteDataProp.isusing ==
          this.$i18n.t("label.emailtocloudcc.button.enable")
            ? this.$i18n.t("label.emailsync.status.disabel")
            : this.$i18n.t("label.emailtocloudcc.button.enable");
      }
    },
    //根据id获取伙伴云信息

    async queryPartnerById() {
      await queryPartnerById({ id: this.editSiteDataProp.id });
    },
    //保存
    async saveEdit() {
      // 保存修改的参数
      let option = {
        id: this.editSiteDataProp.id,
        name: this.ruleForm.name, //名称
        dir_2rd: this.ruleForm.url, //二级域名
        description: this.ruleForm.desc,
      };
      // 如果二级域名修改了  要验证二级域名可用性
      if (this.backupUrl !== this.ruleForm.url) {
        let result = await checkPartnerSecond({ second: this.ruleForm.url });
        if (result.result) {
          if (result.returnCode == "1") {
            this.info = this.$i18n.t(
              "label.partnerCloud.basicSetting.available"
            ); //域名可用
            this.urlSuccess = true;
            this.isSuccess = true;
            let res = await savePartner(option);
            if (res.result == true) {
              this.editSiteDataProp.name = this.ruleForm.name;
              this.editSiteDataProp.description = this.ruleForm.desc;
              this.editSiteDataProp.dir_2rd =
                this.editSiteDataProp.baseUrl + this.ruleForm.url;
              this.isEditContent = false;
            }
          } else {
            this.info = result.data;
            this.urlSuccess = true;
            this.isSuccess = false;
          }
        }
      } else {
        // 没有修改就直接保存
        let res = await savePartner(option);
        if (res.result == true) {
          this.editSiteDataProp.name = this.ruleForm.name;
          this.editSiteDataProp.description = this.ruleForm.desc;
          this.editSiteDataProp.dir_2rd =
            this.editSiteDataProp.baseUrl + this.ruleForm.url;
          this.isEditContent = false;
        }
      }
    },
    editContent() {
      this.isEditContent = true;
      this.urlSuccess = false;
    },
    cancle() {
      this.isEditContent = false;
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveEdit();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/dialogStyle.scss";
::v-deep .el-input {
  width: 220px;
}
::v-deep .el-button {
  width: 69px;
  height: 30px;
  padding: 9px;
}
.set-up-page {
  padding: 30px 10px;
  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .describe {
    margin-bottom: 10px;
  }
  .domain-name {
    margin-bottom: 10px;
    ::v-deep .el-button {
      margin-left: 10px;
      color: #006dcc;
    }
  }
  .status {
    margin-bottom: 10px;
    .titles {
      font-weight: bold;
      margin-right: 100px;
    }
    .template {
      font-weight: bold;
      margin-right: 70px;
    }
    ::v-deep .el-button {
      margin-left: 10px;
      color: #006dcc;
    }
  }
}
</style>