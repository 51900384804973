<template>
  <div class="selectProfile" @click.native.capture="hideBtn">
    <!-- 可选菜单  已选菜单（勾选的菜单将同时显示于移动端） -->
    <div class="shuttleBox">
      <shuttle-box
        ref="shuttleBox"
        :title="[
          $t('label.partner.workspace.tab.option'),
          $t('label.partnerCloud.menu.selectedMenu'),
        ]"
        :viewUnselectedFieldList="viewUnselectedFieldList"
        :viewSelectedFieldArr="viewSelectedFieldArr"
        :showCheckBox="showCheckBox"
        :canUsingMobileList="canUsingMobileList"
        @selFun="parentFn"
      ></shuttle-box>
    </div>
    <!-- 底部按钮组 -->
    <div class="btnGroup">
      <el-button
        style="margin-left: 10px"
        @click="save"
        type="primary"
        size="mini"
      >
        <!-- 保存 -->
        {{ $t("label.save") }}
      </el-button>
      <el-button @click="cancelNewBuid" size="mini">
        <!-- 取消 -->
        {{ $t("label.cancel") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import shuttleBox from "@/views/systemSettings/components/partnerCloudSetting/components/shuttleBox.vue";
import { register, savePartnerTab } from "./api";
export default {
  props: {
    partnerid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showCheckBox: true,
      canUsingMobileList: null,
      viewUnselectedFieldList: null,
      viewSelectedFieldArr: null,
      finalResult: [],
    };
  },
  mounted() {
    this.queryMenu();
  },
  watch: {
    partnerid() {
      this.queryMenu();
    },
  },
  methods: {
    hideBtn() {
      if (this.$refs.shuttleBox !== undefined) {
        this.$refs.shuttleBox.hideBtn();
      }
    },
    cancelNewBuid() {
      this.$Bus.$emit("close-other-edit");
      this.$emit("closeNewBuild");
    },
    async save() {
      if (this.finalResult.tabids.split(",")[0] == "") {
        this.$message.error(this.$i18n.t("label.partnerCloud.selectOne")); //至少选择一个菜单
      } else {
        this.finalResult.partnerid = this.partnerid;
        let result = await savePartnerTab(this.finalResult);
        if (result.result) {
          this.$message.success(this.$i18n.t("savesuccess")); //保存成功
        }
      }
    },
    parentFn(payload) {
      this.finalResult = payload;
    },
    async queryMenu() {
      let obj = {
        id: this.partnerid,
      };
      let result = await register(obj);
      result.data.tabList.forEach((res) => {
        res.labelName = res.label;
        res.ismobiletab = res.ismobiletab == "true" ? true : false;
      });
      result.data.selectedtabList.forEach((res) => {
        res.id = res.tabid;
        res.labelName = res.label;
        res.active2app = res.active2app == "false" ? false : true;
        res.ismobiletab = res.ismobiletab == "true" ? true : false;
      });
      this.canUsingMobileList = result.data.canUsingMobileList;
      this.viewUnselectedFieldList = result.data.tabList;
      this.viewSelectedFieldArr = result.data.selectedtabList;
    },
  },
  components: {
    shuttleBox,
  },
};
</script>

<style lang="scss" scoped>
.shuttleBox {
  height: calc(100% - 30px);
  padding-bottom: 60px;
  overflow: auto;
}
.selectProfile {
  padding: 30px 10px;
  height: 100%;
}
.btnGroup {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid #dddbda;
  align-items: center;
  padding-right: 20px;
}
::v-deep .el-button {
  font-size: 12px;
  padding: 8px 19px;
}
</style>