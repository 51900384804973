<template>
  <div class="edit-site">
    <!-- 设置  启用范围  菜单  登录页面-->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="$t('label.emailsync.form.seversetting')" name="first"><set-up-page v-if="activeName == 'first'" :editSiteData="editSiteData"></set-up-page></el-tab-pane>
      <el-tab-pane :label="$t('label_enable_range')" name="second"><enable-range v-if="activeName == 'second'" :partnerid="editSiteData.id"></enable-range></el-tab-pane>
      <el-tab-pane :label="$t('label_partner_workspace_tab')" name="third"><setting-menu v-if="activeName == 'third'" :partnerid="editSiteData.id"></setting-menu></el-tab-pane>
      <el-tab-pane :label="$t('label.partner.workspace.login')" name="fourth"><login-setting v-if="activeName == 'fourth'" :partnerid="editSiteData.id"></login-setting></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import setUpPage from "@/views/systemSettings/components/partnerCloudSetting/components/setUpPage.vue"
import enableRange from "@/views/systemSettings/components/partnerCloudSetting/components/enableRange.vue"
import settingMenu from "@/views/systemSettings/components/partnerCloudSetting/components/menu.vue"
import loginSetting from "@/views/systemSettings/components/partnerCloudSetting/components/loginSetting.vue"
  export default {
    data () {
      return {
        editSiteData:JSON.parse(this.$route.query.editData),
        //当前选中标签
        activeName: 'first'
      }
    },
    mounted(){
        this.$Bus.$on('close-other-edit',()=>{
          this.activeName = 'first'
        })
    },
    activated(){
        this.editSiteData = JSON.parse(this.$route.query.editData);
        this.activeName = 'first'
    },
    methods: {
      //更改当前页面
      handleClick () {

      }
    },
    components:{
        setUpPage,
        enableRange,
        settingMenu,
        loginSetting
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep .el-tab-pane{
    height: 100%;
  }
  ::v-deep .el-tabs__content{
    height: calc(100% - 60px);
    overflow-y: auto;
  }
  ::v-deep .el-tabs{
    height: 100%;
  }
  .edit-site {
    height: 100%;
    color: #080707;
    height: 100%;
    ::v-deep .el-tabs__nav {
      margin-left: 20px;
    }
    ::v-deep .el-tabs__item {
      padding: 0 40px;
    }
    ::v-deep .el-tabs__item.is-active {
      color: #080707;
      font-weight: bold;
    }
  }
  ::v-deep .menu{
    height: 100%;
  }
</style>